import { Link, StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import PropTypes from "prop-types"
import React from "react"

const Header = ({ siteTitle }) => {
  return (
    <StaticQuery
      query={graphql`
      query {
        logo: file(relativePath: {eq: "logo.jpg"}) {
          childImageSharp {
            fixed( width: 150, quality: 100 ) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        sheryl: file(relativePath: {eq: "sheryl.jpg"}) {
          childImageSharp {
            fixed( width: 50, quality: 100 ) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `}
    render={ data => (
      
      <header>
          <ul id="nav">
            <li><Link to={"/"} activeClassName="active">Home</Link></li>
            <li><Link to={"/mission/"} activeClassName="active">Mission</Link></li>
            <li><Link to={"/define/"} activeClassName="active">Define</Link></li>
            <li><Link to={"/discover/"} activeClassName="active">Discover</Link></li>
            <li><Link to={"/design/"} activeClassName="active">Design</Link></li>
            <li><Link to={"/deliver/"} activeClassName="active">Deliver</Link></li>
            <li><Link to={"/portfolio/"} activeClassName="active" partiallyActive={true}>Portfolio</Link></li>
            <li className="bio"><Link to={"/bio/"}><Img fixed={data.sheryl.childImageSharp.fixed} alt="Sheryl Fiegel photo thumbnail" /></Link></li>
        </ul>
        <div id="header">
            <Link to={"/"}>
              <Img fixed={data.logo.childImageSharp.fixed} alt={siteTitle} />
            </Link>
            <h2>Curators for the corporate environment</h2>
        </div>
      </header> 
    )}

    />
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header