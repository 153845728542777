/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import Sidebar from "./sidebar"
import Footer from "./footer"
import "./style.css"

const Layout = ({ children, sidebar, slug }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  // https://react-cn.github.io/react/tips/if-else-in-JSX.html
  var sidebarContent;
  if ( "hidden" === sidebar ) {
    sidebarContent = '';
  } else if (slug) {
    sidebarContent = <Sidebar page={slug} />;
  } else {
    sidebarContent = <Sidebar />
  }

  return (
    <div id="wrapper">
      <Header siteTitle={data.site.siteMetadata.title} />
        <div id="main">
          {children}
        
          {sidebarContent}
        </div>
        <Footer />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
