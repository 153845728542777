import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import { Link } from "gatsby"

const Sidebar = ({page}) => {
  
  const data = useStaticQuery(graphql`
    query {
      items: allWordpressWpPortfolio(limit: 5, sort: {order: DESC, fields: date}) {
        nodes {
          featured_media {
            localFile {
              childImageSharp {
                fixed(height: 70, width: 119, quality: 100) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
          id
          slug
          title
          date
        }
      }

      sheryl: file(relativePath: {eq: "sheryl_bio.jpg"}) {
        childImageSharp {
          fixed( width: 119, height: 221, quality: 100 ) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  
  
  var sidebarContent;

  if ( "bio" === page ) {
    sidebarContent = <Img fixed={data.sheryl.childImageSharp.fixed} alt="Sheryl Fiegel photo" />
  } else {
    sidebarContent = data.items.nodes.map(item => (
      <Link key={item.id} to={`/portfolio/${item.slug}/`}>
        <Img fixed={item.featured_media.localFile.childImageSharp.fixed} alt={item.title} />
      </Link>
    ))
  }

  return (
    <div id="aside">
        {sidebarContent}
    </div>
  )

}

export default Sidebar